import React from 'react';
import Map, {Marker} from 'react-map-gl';
import mapboxgl from "mapbox-gl";
import Typography from "@mui/material/Typography";

export const VenueMap = ({longitude, latitude, title}) => {
    const popup = (text) => {
        return new mapboxgl.Popup().setText(text);
    };

    return longitude && latitude && (
        <>
            <div className='font-500 mb-4'>
                <Typography variant='h6'>Location on map</Typography>


            </div>
            <div
                className='w-full h-[400px] bg-amber-500'>

                <Map
                    mapboxAccessToken={process.env.MAP_TOKEN}
                    cooperativeGestures
                    initialViewState={{
                        longitude,
                        latitude,
                        zoom: 12
                    }}
                    // style={{height: '50%'}}
                    mapStyle="mapbox://styles/mapbox/streets-v9"
                >
                    <Marker
                        key={title}
                        longitude={longitude}
                        latitude={latitude}
                        popup={popup(title)}
                        color='red'
                        onClick={(args) => console.log(args)}
                    />
                </Map>
            </div>
        </>
    )
}
