import React from 'react'
import Typography from "@mui/material/Typography";
import AttachMoney from "@mui/icons-material/AttachMoney";
import CalendarMonth from "@mui/icons-material/CalendarMonth";
import ScheduleIcon from "@mui/icons-material/Schedule";
import ListItem from "@mui/material/ListItem";


export const Icons = ({deposit, time, area, room}) => {
    return (
        <ListItem alignItems="center" sx={{pl: 0, justifyContent: 'space-between', mt: 3}}>
            {time && <Icon
                Icon={CalendarMonth}
                title='Min rental'
                description={`${time} days`}
            />}
            {room && <Icon
                Icon={ScheduleIcon}
                title='Operating hours'
                description={room}
            />}
             <Icon
                Icon={AttachMoney}
                title='Deposit'
                description={deposit ? 'required': 'not-required'}
            />
            {/*{area && <Icon*/}
            {/*    Icon={AspectRatio}*/}
            {/*    title='Square ft.'*/}
            {/*    description={area}*/}
            {/*/>}*/}


        </ListItem>
    )
}


const Icon = ({Icon, title, description}) => {
    return (
        <div className='flex'>
            <Icon
                fontSize='medium'
                htmlColor='#56596C'
            />
            <div className='flex flex-col mx-4'>
                <Typography
                    component="span"
                    variant="body2"
                    color="text.secondary"
                >
                    {title}
                </Typography>
                <Typography
                    component="span"
                    variant="body2"
                    color="text.primary">
                    {description}
                </Typography>
            </div>
        </div>
    )
}
