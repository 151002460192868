import React, {useEffect} from 'react';
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import MenuIcon from '@mui/icons-material/Menu';


import {useAuth0} from "@auth0/auth0-react";
import {Link} from "react-router-dom";
import {useSupabaseAuth} from "../../hooks";

export const Login = () => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const {loginWithRedirect, user, isLoading, logout} = useAuth0();
    useSupabaseAuth()


    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <>
        {user ? <div
                className='border-2 border-gray-300 rounded-2xl gap-2 ml-3'>
                <Button
                    sx={{display: 'flex', gap: 2}}
                    onClick={handleClick}>
                    <MenuIcon color='disabled'/>

                    <Avatar alt={user?.name} src={user?.picture} sx={{bgcolor: '#56596C'}}/>
                </Button>
            </div>
            : <div
                onClick={() => loginWithRedirect()}
                className='border border-black rounded-2xl px-5 py-[7px] cursor-pointer'>Log in</div>}

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <List>
                    {!isLoading && !user && <div className='hover:bg-gray-100 cursor-pointer'>
                        <ListItem onClick={() => loginWithRedirect()}>
                            <Typography>Sign in</Typography>
                        </ListItem>
                    </div>}
                    {!isLoading && user && (
                        <>
                            <div className='hover:bg-gray-100 cursor-pointer'>
                                <Link to='/account'>
                                    <ListItem>
                                        <Typography>Account</Typography>
                                    </ListItem>
                                </Link>
                            </div>

                            <div className='hover:bg-gray-100 cursor-pointer'>
                                <Link to='/account/listings'>
                                    <ListItem>
                                        <Typography>My properties</Typography>
                                    </ListItem>
                                </Link>
                            </div>
                            <div className='hover:bg-gray-100 cursor-pointer'>
                                <ListItem onClick={() => logout()}>
                                    <Typography>Log out</Typography>
                                </ListItem>
                            </div>
                        </>)}
                </List>
            </Popover>
        </>

    )
}