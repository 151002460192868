import React, {useState} from 'react'
import Typography from "@mui/material/Typography";

import List from '@mui/material/List';
import {ListItemButton, Popover} from "@mui/material";
import ListItemText from "@mui/material/ListItemText";

export const SelectType = ({onTypeChange, selectedType = null}) => {
    console.log(selectedType)
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const [type, setType] = useState(selectedType);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const onTypeSelect = (type: string) => {
        setType(type)
        handleClose()
        onTypeChange(type)
    }
    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (<>

            <div
                onClick={handleClick}
                className='hover:bg-slate-100 rounded-3xl py-4 px-6 cursor-pointer flex-1'
            >
                <Typography>
                    Storage type
                </Typography>
                <Typography color='text.secondary'>
                    {type || 'Select type'}
                </Typography>
            </div>


            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <List >
                    <ListItemButton
                        onClick={() => onTypeSelect('')}
                        sx={{px: 5}}>
                        <ListItemText primary="All" />
                    </ListItemButton>
                    <ListItemButton
                        onClick={() => onTypeSelect('warehouse')}
                        sx={{px: 5}}>
                        <ListItemText primary="Warehouse" />
                    </ListItemButton>
                    <ListItemButton
                        onClick={() => onTypeSelect('self-storage')}
                        sx={{px: 5}}>
                        <ListItemText primary="Self-storage" />
                    </ListItemButton>
                    <ListItemButton
                        onClick={() => onTypeSelect('garage')}
                        sx={{px: 5}}>
                        <ListItemText primary="Garage" />
                    </ListItemButton>
                </List>
            </Popover>
        </>

    )
}