import React from 'react'
import {createRoot} from 'react-dom/client';
import "mapbox-gl/dist/mapbox-gl.css";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {Auth0Provider} from '@auth0/auth0-react';
import {createTheme, ThemeProvider} from '@mui/material/styles';


import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {PlacePage} from "./pages/PlacePage";
import {Account} from "./pages/Account";
import {Bookings} from "./pages/Account/Bookings";
import {Personal} from "./pages/Account/Personal";
import {HelpCenter} from "./pages/Account/HelpCenter";
import {GeoapifyContext} from '@geoapify/react-geocoder-autocomplete';
import {Listings} from "./pages/Account/Listings";
import {ListingsNew} from "./pages/ListingsNew";
import {Landing} from "./pages/Landing";
import {SearchAds} from "./pages/SearchAds";

const root = createRoot(document.getElementById('root'));
const theme = createTheme({
    typography: {
        h1: {
            fontSize: '40px',
            lineHeight: '52px',
            fontWeight: 'bold'
        },
        h3: {
            fontSize: '24px',
            lineHeight: '36px',
            fontWeight: 'bold'
        },
        h4: {
            fontSize: '18px',
            lineHeight: '27px',
            fontWeight: 'bold'
        },
        fontFamily: [
            'Lato',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
    },
});

root.render(
    <ThemeProvider theme={theme}>
        <Auth0Provider
            domain={process.env.AUTH0_DOMAIN}
            clientId={process.env.AUTH0_CLIENT_ID}
            authorizationParams={{
                redirect_uri: window.location.origin + '/account'
            }}
        >
            <GeoapifyContext apiKey={process.env.GEOAPIFY_KEY}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <BrowserRouter>
                        <Routes>
                            <Route path="/" element={<Landing/>}/>
                            <Route path="/search" element={<SearchAds/>}/>

                            <Route path="places/:id" element={<PlacePage/>}/>
                            <Route path="account" element={<Account/>}/>
                            <Route path="account/personal" element={<Personal/>}/>
                            <Route path='account/bookings' element={<Bookings/>}/>
                            <Route path='account/listings' element={<Listings/>}/>
                            <Route path='account/listings/new' element={<ListingsNew/>}/>
                            <Route path='account/help' element={<HelpCenter/>}/>
                        </Routes>
                    </BrowserRouter>
                </LocalizationProvider>
            </GeoapifyContext>
        </Auth0Provider>
    </ThemeProvider>
)
