import React from 'react'
import {Icons} from "./Icons";
import {VenueMap} from "./VenueMap";

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import {Ad} from "../../types";
import {Facilities} from "./Facilities";
import Avatar from "@mui/material/Avatar";


export const Description = (ad: Ad) => {
    const {
        description,
        name,
        longitude,
        latitude,
        city
    } = ad;
    return (
        <div className='max-w-[650px]'>
            <div className='mb-8 text-4xl lg:text-5xl'>{name}</div>
            <Typography variant='h5' mb={5} color='text.secondary'>{city}</Typography>

            <div className='mb-3 flex'>
                <Avatar alt={ad?.name} src={ad?.images?.[0]}/>
                <div className='flex flex-col mx-4'>
                    <Typography
                        component="span"
                        variant="body2"
                        color="text.primary"
                        sx={{textTransform: 'capitalize'}}
                    >
                        {ad?.storage_type}
                    </Typography>
                    <Typography
                        component="span"
                        variant="body2"
                        color="text.secondary">
                        {ad?.area} square ft.
                    </Typography>
                </div>
            </div>

            <List sx={{width: '100%', bgcolor: 'background.paper'}}>
                <Divider variant="inset" component="li" sx={{ml: 0}}/>
                <Icons
                    deposit={ad.deposit_required}
                    time={ad.minimum_rental}
                    area={ad.area}
                    room={ad.operating_hours}
                />

                <ListItem alignItems="flex-start" sx={{pl: 0, mt: 6}}>
                    <ListItemText
                        primary={<Typography variant='h4' mb={2}>
                            Description
                        </Typography>}
                        secondary={
                            <Typography
                                sx={{display: 'inline'}}
                                component="span"
                                variant="body2"
                                color="text.primary"
                            >
                                {description} </Typography>
                        }
                    />
                </ListItem>

                {ad?.price_includes?.length && <>
                    <ListItem alignItems="flex-start" sx={{pl: 0}}>
                        <ListItemText
                            primary="Price includes:"
                            secondary={
                                <React.Fragment>
                                    <Typography
                                        sx={{display: 'inline'}}
                                        variant="body2"
                                        color="text.primary"
                                    >
                                        <ul>
                                            {
                                                ad?.price_includes?.map((incl, key) => <li key={key}>{incl}</li>)
                                            }
                                        </ul>
                                    </Typography>

                                </React.Fragment>
                            }
                        />
                    </ListItem></>}
                {ad?.addons?.length && <> <Divider variant="inset" component="li"/>

                    <ListItem alignItems="flex-start" sx={{pl: 0}}>
                        <ListItemText
                            primary="Add-ons"
                            secondary={
                                <React.Fragment>
                                    <Typography
                                        sx={{display: 'inline'}}
                                        component="span"
                                        variant="body2"
                                        color="text.primary"
                                    >
                                        <ul>
                                            {
                                                ad?.addons?.map((addon) => <li>{addon}</li>)
                                            }
                                        </ul>
                                    </Typography>
                                </React.Fragment>
                            }
                        />
                    </ListItem> </>}
                {ad?.addons?.length && <>  <Divider variant="inset" component="li"/>

                    <ListItem alignItems="flex-start" sx={{pl: 0}}>
                        <ListItemText
                            primary="Rules"
                            secondary={
                                <React.Fragment>
                                    <Typography
                                        sx={{display: 'inline'}}
                                        component="span"
                                        variant="body2"
                                        color="text.primary"
                                    >
                                        <ul>
                                            {
                                                ad?.rules?.map((rule) => <li>{rule}</li>)
                                            }
                                        </ul>
                                    </Typography>
                                </React.Fragment>
                            }
                        />
                    </ListItem> </>}
                   <Facilities />

            </List>

        </div>
    )
}
