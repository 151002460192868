import React, {useState} from 'react';
import {Link} from "react-router-dom";
import {styled} from "@mui/material/styles";
import Button from "@mui/material/Button";
import CloseIcon from '@mui/icons-material/Close';
import {Login} from "../Login";
import {SelectDate} from "./SelectDate";
import {SelectWhere} from "./SelectWhere";
import {SelectType} from "./SelectType";
import {Box} from "@mui/material";
import {MobileHeader} from "./MobileHeader";
import {HeaderSearchButton} from "./HeaderSearchButton";


export const Header = (params) => {
    const {
        onTypeChange,
        cities = [],
        onCityChange,
        selectedCity,
        selectedType,
        checkOutDate,
        checkInDate,
        onCheckInDateChange,
        onCheckOutDateChange
    } = params;
    const [isMobileSearchOpen, setMobileSearchOpen] = useState(false);

    return (
        <header className="min-h-20 shadow shadow-gray-10 px-8 py-3">
            <nav className="items-center flex w-full justify-between">
                <Link to='/'>
                    <div className="flex-1">
                        <Box
                            component="img"
                            sx={{
                                height: 90,
                                width: 180,
                            }}
                            alt="logo"
                            src="https://res.cloudinary.com/dhlzvktzj/image/upload/v1714217409/sunni/b_xc7mqh.png"
                        />
                    </div>
                </Link>
                <div
                    id='search-bar-holder'
                    className='lg:block hidden'>
                    <div
                        id='search-bar'
                        className='shadow shadow-gray-300 rounded-3xl w-[700px] h-[76px] flex justify-between items-center'>
                        <SelectWhere cities={cities} onCityChange={onCityChange} selectedCity={selectedCity}/>
                        <SelectDate title='Check in' selectedDate={checkInDate} onChange={onCheckInDateChange}/>
                        <SelectDate title='Check out' selectedDate={checkOutDate} onChange={onCheckOutDateChange} minDate={checkInDate}/>
                        <SelectType onTypeChange={onTypeChange} selectedType={selectedType}/>
                        <Link to='/search' state={{selectedCity, selectedType, checkInDate, checkOutDate}}>
                            <HeaderSearchButton/>
                        </Link>
                    </div>
                </div>
                {
                    isMobileSearchOpen
                        ? <CloseIcon fontSize='large' onClick={() => setMobileSearchOpen(false)}/>
                        : <Login/>
                }

            </nav>
            <div className='lg:hidden block'>
                <MobileHeader
                    onCityChange={onCityChange}
                    onTypeChange={onTypeChange}
                    cities={cities}
                    isOpen={isMobileSearchOpen}
                    setOpen={setMobileSearchOpen}
                    selectedCity={selectedCity}
                    selectedType={selectedType}
                    checkOutDate={checkOutDate}
                    checkInDate={checkInDate}
                    onCheckInDateChange={onCheckInDateChange}
                    onCheckOutDateChange={onCheckOutDateChange}
                />

            </div>
        </header>
    )
}