import React from 'react'
import {Link} from "react-router-dom";
import {CardActionArea, CardMedia} from "@mui/material";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";


export const PreviewCard = ({id, title, price, place, image, onClick, city}) => (
  <Link
      className='flex-1'
      to={`/places/${id}`}>
      <Card
          sx={{ borderRadius: '12px'}}
          // variant="outlined"
      >
          <CardActionArea>
              <CardMedia
                  sx={{ height: 216, width: '100%', }}
                  component="div"
                  image={image}
              />
              <CardContent>
                  <div
                      className='font-bold truncate text-bold mb-1'>
                      {title}
                  </div>
                  <div
                      className='truncate text-thin text-gray-600 text-xs mb-2'>
                      {city}
                  </div>
                  <Typography
                  >
                      <span className='text-bold text-2xl'>£{price}</span>
                      <span className='text-gray-400 text-thin text-xs'>/month</span>
                  </Typography>
              </CardContent>
          </CardActionArea>
      </Card>
  </Link>
)
