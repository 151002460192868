import React, {useState} from 'react'
import Typography from "@mui/material/Typography";
import {Popover} from '@mui/material';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import dayJs from 'dayjs'


export const SelectDate = (params) => {
    const {title, selectedDate = null, onChange, minDate = null} = params;
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const formattedDate = dayJs(selectedDate)
    const formattedMinDate = minDate ? dayJs(minDate) : null
    const [date, setDate] = useState(selectedDate ? formattedDate : null);

    console.log({ title, formattedDate, date})

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const onDateChange = (value) => {
        setDate(value)
        handleClose()
        onChange(value.format())
    }

    return (
        <>

            <div
                onClick={handleClick}
                className='hover:bg-slate-100 rounded-3xl py-4 px-6 cursor-pointer flex-1'>

                <Typography>
                    {title}
                </Typography>
                <Typography color='text.secondary'>
                    {date?.format('D MMM') || 'Select a date'}
                </Typography>
            </div>

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <DateCalendar
                    value={date}
                    onChange={onDateChange}
                    disablePast
                    minDate={formattedMinDate}
                />
            </Popover>
        </>

    )
}