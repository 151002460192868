import React, {useEffect, useState} from 'react'
import {Ad} from "../../types";
import {Header} from "../../components/Header";
import {Ads} from "../../components/Ads";
import {getAds} from "../../api/supabase";
import {Box} from "@mui/material";
import {Footer} from "../../components/Footer";
import {FAQ} from "./FAQ";
import {AdsMap} from "../../components/AdsMap";

export const Landing = () => {
    const [ads, setAdds] = useState<Ad[]>([])
    const [showMap, setShowMap] = useState(false)
    const [type, onTypeChange] = useState()
    const [city, onCityChange] = useState()
    const [checkInDate, onCheckInDateChange] = useState()
    const [checkOutDate, onCheckOutDateChange] = useState()

    useEffect(() => {
        const fetchAds = async () => {
            const ads = await getAds();
            setAdds(ads);
        }
        fetchAds()
            .catch(console.error);
    }, [])

    const filteredAds = ads.filter((ad) => {
        const typeFilter = Boolean(type) ? ad?.storage_type.includes(type) : true
        const cityFilter = Boolean(city) ? ad?.city === city : true
        return typeFilter && cityFilter
    })
    const allCities = [...new Set(ads.map(ad => ad.city))].sort()

    return (<>
        <Header
            onCityChange={onCityChange}
            onTypeChange={onTypeChange}
            cities={allCities}
            selectedCity={city}
            selectedType={type}
            checkInDate={checkInDate}
            checkOutDate={checkOutDate}
            onCheckInDateChange={onCheckInDateChange}
            onCheckOutDateChange={onCheckOutDateChange}
        />
        <div
            style={{
                width: '100%',
                height: '333px',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundImage: 'url(https://res.cloudinary.com/dhlzvktzj/image/upload/v1714218099/sunni/hero-short_x7vvfx.jpg)'
            }}
            className="relative hidden lg:block bg-no-repeat bg-cover">

            <div className='h-full absolute top-0 bottom-0 flex justify-center items-center flex-col pl-[150px]'>
                <div className='bg-white  px-10 rounded-2xl mb-10'>
                    <div className='text-[64px]  font-bold py-4 leading-[80px]' >
                        Space for
                        <div>
                            your {'\u00A0'}
                            <span className='text-[#c83232]'>
                        growth
                    </span>
                        </div>
                    </div>
                </div>
                <div className='text-white text-[21px]'>
                    Find a short-term storage that fits your size
                </div>
            </div>

        </div>
        <div className='flex justify-center pt-4'>
            <Ads
                showTitle
                showMap={showMap}
                setShowMap={setShowMap}
                ads={filteredAds}
                totalAdNumber={ads.length}
            />
            {showMap && <div className='w-[30%] h-auto'>
                <AdsMap ads={filteredAds}/>
            </div>}
        </div>
        <FAQ />
        <Footer/>
    </>);
}