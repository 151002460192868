import React, {useEffect, useState} from 'react'
import {useLocation, useParams} from 'react-router-dom';
import {Description} from "./Description";
import {Aside} from "./Aside";
import {Footer} from "../../components/Footer";

import {Ad} from '../../types'
import {PlacePageCarousel} from "./PlacePageCarousel";
import {getAd, getAds} from "../../api/supabase";
import {Header} from "../../components/Header";
import {VenueMap} from "./VenueMap";


export const PlacePage = () => {
    const {id} = useParams()
    const [ads, setAdds] = useState<Ad[]>([])
    const [ad, setAdd] = useState<Ad>()
    const [type, onTypeChange] = useState()
    const [city, onCityChange] = useState()
    const allCities = [...new Set(ads.map(ad => ad.city))].sort()
    console.log({allCities})

    useEffect(() => {
        if (!id) return
        const fetchAds = async () => {
            const ad = await getAd(id);
            const ads = await getAds();
            setAdds(ads);
            setAdd(ad);
        }
        fetchAds()
            .catch(console.error);
    }, [])

    return (
        <div>
            <Header
                onCityChange={onCityChange}
                onTypeChange={onTypeChange}
                cities={allCities}
            />
            <PlacePageCarousel images={ad?.images}/>
            <div className='max-w-[1024px] w-auto m-auto my-5 lg:my-20 relative p-5'>
                <div className='flex gap-x-16 flex-wrap'>
                    <Description {...ad}/>
                    <Aside {...ad} extraClass='lg:block hidden'  />
                </div>

                <VenueMap title={ad?.name} latitude={ad?.latitude} longitude={ad?.longitude}/>


            </div>
            <Aside {...ad} extraClass='block lg:hidden mt-5 sticky bottom-0' isFlatBottom />

            <Footer/>
        </div>
    )
}
