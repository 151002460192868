import { createClient } from '@supabase/supabase-js'
import {Ad, SupportedLanguages} from "./types";

const supabase = createClient(process.env.SUPABASE_URL, process.env.SUPABASE_KEY)

export const getAdsByEmails = async (email: string) => {
  let { data, error } = await supabase
      .from('storage')
      .select('*')
      .eq('email', email);


  console.log(data, error)
  return data;
}

export const getAds = async () => {
  let { data, error } = await supabase
    .from('storage')
    .select('*')


  console.log(data, error)
  return data;
}

export const getAd = async (id: string) => {
  let { data, error } = await supabase
    .from('storage')
    .select('*')
    .eq('id', id)

  console.log(data, error)
  return data[0];
}

export const getUser = async () => {
  const { data: { user } } = await supabase.auth.getUser()
  console.log({user})
  return user
}

export const updateAd = async () => {
  // let { data, error } = await supabase
  //     .from('storage')
  //     .insert({name: 'Test Venue', city: 'Mishaton'})
  //
  // console.log(data)
  // console.log(error)
}

export const signIn = async ({email, password}) => {
  await supabase.auth.signInWithPassword({
    email,
    password
  })
}