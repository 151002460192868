import React from 'react'
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import {Label} from "@mui/icons-material";
import {Header} from "../../../components/Header";



export const HelpCenter = () => {
    return (<>
        <Header />
        <div
            className='max-w-screen-lg mx-auto mt-10'
        >
            <Typography variant='h3' mb={3}>
                Help Center
            </Typography>

            <div className=''>
                <FormControl defaultValue="" required>
                    <input placeholder="Describe your problem" />
                </FormControl>
            </div>
        </div>
    </>)
}