import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {TimePicker} from "@mui/x-date-pickers/TimePicker";
import TextField from "@mui/material/TextField";
import React, {useState} from "react";
import {Button} from "../../../components/Button";


export const Form = ({ad, onSubmit}) => {
    const [startDateValue, setStartDate] = useState(null);
    const [endDateValue, setEndDate] = useState(null);
    const [startTimeValue, setStartTime] = useState(null);
    const [endTimeValue, setEndTime] = useState(null);
    const [comment, changeComment] = useState('')
    const [email, setEmail] = useState('')

    const [isError, setError] = useState(null)

    const onButtonPress = () => {
        const isFilledIn = startDateValue && endDateValue && startTimeValue && endTimeValue && email;
        if (!isFilledIn) {
            return setError(true)
        }
        onSubmit({
            startDate: startDateValue?.format('DD MMMM YYYY'),
            endDate: endDateValue?.format('DD MMMM YYYY'),
            startTime: startTimeValue?.format('HH:mm'),
            endTime: endTimeValue?.format('HH:mm'),
            total: endTimeValue.diff(startTimeValue, 'hours'),
            comment,
            venue: ad.name,
            url: `https://venuse.xyz/places/${ad.id}`,
            email
        })
    }

    return (
        <div>

            <div className='mb-4 text-2xl font-bold'>
                Contact {ad.name}
            </div>

            <div className='mb-4 text-lg text-secondary'>
                Rent period
            </div>

            <div className='flex justify-between mb-4'>
                <DatePicker
                    disablePast
                    value={startDateValue}
                    // disableOpenPicker
                    slotProps={{}}
                    onChange={(e) => setStartDate(e)}
                    sx={{
                        // width: '252px',
                        mr: 2
                    }}
                    label="Check in"/>
                <TimePicker
                    timeSteps={{minutes: 60}}
                    value={startTimeValue}
                    onChange={(e) => setStartTime(e)}
                    sx={{
                        // width: '252px',
                    }}
                    ampm={false}
                    label="From"/>

            </div>
            <div className='flex justify-between mb-4'>
                <DatePicker
                    disablePast
                    value={endDateValue}
                    onChange={(e) => setEndDate(e)}
                    sx={{
                        // width: '252px',
                        mr: 2
                    }}
                    label="Check out"/>
                <TimePicker
                    ampm={false}
                    timeSteps={{minutes: 60}}
                    value={endTimeValue}
                    onChange={(e) => setEndTime(e)}
                    sx={{
                        // width: '252px',
                    }}
                    label="To"/>
            </div>

            <div className='mb-4 text-lg text-secondary'>
                Contact details
            </div>
            <div className='mb-4'>
                <TextField
                    label="Email"
                    type='email'
                    placeholder='sample@mail.com'
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    multiline
                    sx={{
                        width: '100%',
                    }}
                />
            </div>

            <div className='mb-8'>
                <TextField
                    label="Comment"
                    value={comment}
                    placeholder='Your preferences, questions and anything you consider important for this request'
                    onChange={(e) => changeComment(e.target.value)}
                    multiline
                    rows={2}
                    sx={{
                        width: '100%',
                    }}
                />
            </div>

            <div className='flex justify-center'>
                <div className='w-[330px]'>
                    <Button onClick={onButtonPress}>Message host</Button>
                </div>
            </div>
            <div className='text-orange-800 mt-4 text-center'>
                {isError && 'Please fill in the date, interval and your email'}
            </div>

        </div>
    )
}