import {useAuth0} from "@auth0/auth0-react";
import {useEffect} from "react";
import {signIn} from "../api/supabase";

export const useSupabaseAuth = () => {
    const {user, getIdTokenClaims} = useAuth0();

    useEffect(() => {
        if(user) {
            console.log('Loginning in', user)
            getIdTokenClaims().then((claims) => {
                signIn({email: user.email, password: claims.nonce_2})
            })

        }
    }, [user])
}