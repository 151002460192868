import React from 'react'
import Typography from "@mui/material/Typography";
import {useAuth0} from "@auth0/auth0-react";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import {Header} from "../../components/Header";
import {CardActions, CardMedia} from "@mui/material";
import {Button} from "./Button";
import Avatar from "@mui/material/Avatar";
import {Link} from "react-router-dom";

export const Account = () => {
    const {user} = useAuth0();

    return (
        <>
            <Header
                onTypeChange={console.log}
                cities={[]}
                onCityChange={console.log}
                selectedCity=''
                selectedType=''
                checkOutDate=''
                checkInDate=''
                onCheckInDateChange={console.log}
                onCheckOutDateChange={console.log}
            />
            <div className='max-w-screen-lg mx-auto mt-8 flex'>
                <div className='w-[600px] mr-[66px]'>
                    <Typography variant='h1' sx={{my: 4}}>
                        My account
                    </Typography>
                    <div className='flex items-center'>
                        <Avatar alt={user?.name} src={user?.picture}/>
                        <div className="ml-3">
                            <div>{user?.name}</div>

                            <div className='text-secondary'>{user?.email}</div>
                        </div>
                    </div>

                    <div className='my-10'>
                        <Typography variant='h3'>My bookings</Typography>
                        <div className='text-secondary'>No bookings so far</div>
                    </div>

                    <div className='my-10'>
                        <Typography variant='h4'>My properties</Typography>
                        <div className='text-secondary'>No properties so far</div>
                    </div>

                </div>
                <div className='mt-[47px]'>
                    <Card>
                        <CardMedia
                            sx={{height: 140}}
                            image="https://res.cloudinary.com/dhlzvktzj/image/upload/v1716189084/sunni/Untitleddesign-slimC1_kvzlw0.jpg"
                            title="green iguana"
                        />
                        <CardContent sx={{textAlign: 'center', py: 3}}>
                            <Typography variant="h5" component="div">
                                Rent out your property
                            </Typography>

                            <Typography variant="body2">
                                Increase your revenue
                            </Typography>
                        </CardContent>
                        <CardActions sx={{justifyContent: 'center', pb: 3}}>
                            <Link to='/new'>
                                <Button>Add property</Button>
                            </Link>
                        </CardActions>
                    </Card>
                </div>
            </div>
        </>
    )
}
