import React from 'react'
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import {useAuth0} from "@auth0/auth0-react";
import Avatar from "@mui/material/Avatar";
import {Header} from "../../../components/Header";

export const Personal = () => {
    const {user} = useAuth0();


    return (<>
        <Header />
        <div
            className='max-w-screen-lg mx-auto mt-10 flex'
        >
            <div className='mr-10'>
                <Card sx={{py: 2, px: 4}}>
                    <CardContent>
                        <Avatar
                            alt="user avatar"
                            src={user?.picture}
                            sx={{height: '100px', width: '100px'}}
                        />
                        <Typography>
                            {user.name}
                        </Typography>


                    </CardContent>
                </Card>
            </div>

            <div className='flex-1'>
                <Typography variant='h4' mb={3}>
                    About {user?.given_name}
                </Typography>

                <div className=''>
                    <Typography color='text.secondary'>No description so far</Typography>
                </div>
            </div>


        </div>
    </>)
}