import React, {useState} from 'react'
import Typography from "@mui/material/Typography";
import {ListItemButton, Popover} from "@mui/material";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";

export const SelectWhere = ({cities, onCityChange, selectedCity = null}) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const [city, setCity] = useState(selectedCity);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const onTypeSelect = (city: string) => {
        setCity(city)
        handleClose()
        onCityChange(city)
    }
    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;


    return (
        <>
            <div
                onClick={handleClick}
                className='hover:bg-slate-100 rounded-3xl py-4 px-6 flex-1 cursor-pointer'>
                <Typography>
                    Where
                </Typography>
                <Typography color='text.secondary'>
                    {city || 'Select a city'}
                </Typography>
            </div>

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <List>
                    <ListItemButton
                        onClick={() => onTypeSelect('')}
                        sx={{px: 5}}>
                        <ListItemText primary="All" />
                    </ListItemButton>
                    {
                        cities.map(city => (
                            <ListItemButton
                                key={city}
                                onClick={() => onTypeSelect(city)}
                                sx={{px: 5}}>
                                <ListItemText primary={city}/>
                            </ListItemButton>
                        ))
                    }
                </List>
            </Popover>
        </>

    )
}