import React from 'react'
import {Link} from "react-router-dom";
import {Box, CardActionArea, CardMedia, Chip} from "@mui/material";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";


export const PreviewCard = ({id, title, price, place, image, to = `/places/${id}`, is_published, showStatus}) => (
    <Link
        className='flex-1 relative'
        to={to}>
        <Card
            sx={{border: 'none'}}
            variant="outlined"
        >
            <CardActionArea>
                <CardMedia
                    sx={{height: 320, width: '100%', borderRadius: '12px'}}
                    component="img"
                    image={image}
                />
                {showStatus && <div className='absolute top-5 left-5'>

                    <Chip
                        label={is_published ? 'published' : 'unpublished'}
                        color={is_published ? 'success' : 'warning'}
                        size='small'
                    />

                </div>}
                <CardContent>
                    <Typography
                        sx={{fontWeight: 600}}
                        className='font-bold'
                    >
                        {title}
                    </Typography>
                    <Typography
                    >
                        £ {price} per month
                        <Typography
                            sx={{ml: 1}}

                            color="text.secondary"
                            component="span">
                            {place}
                        </Typography>
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    </Link>
)
