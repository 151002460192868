import React from 'react'
import Typography from "@mui/material/Typography";
import {Header} from "../../../components/Header";

export const Bookings = () => {
    return (<>
        <Header/>
        <div
            className='max-w-screen-lg mx-auto mt-10'
        >
            <Typography variant='h3' mb={3}>
                My bookings
            </Typography>

            <div className=''>
                <Typography color='text.secondary'>No booking found so far</Typography>
            </div>
        </div>
    </>)
}