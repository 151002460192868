import React from "react";
import {styled} from "@mui/material/styles";
import MuiButton from "@mui/material/Button";


export const FullSearchButton = styled(MuiButton)(
    () => `
    background: rgb(200, 50, 50);
    text-transform: capitalize;
    height: 56px;
    width: 100%;
    border-radius: 2.5rem;
    &:hover {
        background: #D72542;
}`)

export const Button = ({children, ...rest}) => {
    return (
        <FullSearchButton
            {...rest}
            variant="contained">
            {children}
        </FullSearchButton>
    )
}