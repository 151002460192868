import React, {useEffect, useState} from 'react'
import {Ad} from "../../types";
import {getAds} from "../../api/supabase";
import {Header} from "../../components/Header";
import {Ads} from "../../components/Ads";
import {Footer} from "../../components/Footer";
import {AdsMap} from "../../components/AdsMap";
import {useLocation} from "react-router-dom";

export const SearchAds = () => {
    const {state} = useLocation()
    console.log(state)
    const [ads, setAdds] = useState<Ad[]>([])
    const [showMap, setShowMap] = useState(false)
    const [type, onTypeChange] = useState(state?.selectedType)
    const [city, onCityChange] = useState(state?.selectedCity)
    const [checkInDate, onCheckInDateChange] = useState(state?.checkInDate)
    const [checkOutDate, onCheckOutDateChange] = useState(state?.checkOutDate)

    useEffect(() => {
        const fetchAds = async () => {
            const ads = await getAds();
            setAdds(ads);
        }
        fetchAds()
            .catch(console.error);
    }, [])

    const filteredAds = ads.filter((ad) => {
        const typeFilter = Boolean(type) ? ad?.storage_type.includes(type) : true
        const cityFilter = Boolean(city) ? ad?.city === city : true
        return typeFilter && cityFilter
    })
    const allCities = [...new Set(ads.map(ad => ad.city))].sort()

    return (<>
        <Header
            onCityChange={onCityChange}
            onTypeChange={onTypeChange}
            cities={allCities}
            selectedCity={city}
            selectedType={type}
            checkInDate={checkInDate}
            checkOutDate={checkOutDate}
            onCheckInDateChange={onCheckInDateChange}
            onCheckOutDateChange={onCheckOutDateChange}
        />
        <div className='flex justify-center pt-4'>
            <Ads
                showMap={showMap}
                setShowMap={setShowMap}
                ads={filteredAds}
                totalAdNumber={ads.length}
            />
            {showMap && <div className='w-[30%] h-auto'>
                <AdsMap ads={filteredAds}/>
            </div>}
        </div>
        <Footer/>
    </>);
}