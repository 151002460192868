import React from 'react'

export const Footer = () => {
    return (
        <div
            className='w-full flex  flex-col items-center bg-[#e3e5eb]  justify-between p-10 border-slate-200 border-t-2'>
            <div className='flex gap-5 pb-10 text-[#D91828]'>
                <div>
                    Contact us
                </div>

                <div>
                    FAQ
                </div>

                <div>
                    Terms of usage
                </div>
            </div>
            <div className='text-gray-600 text-center'>
                Venuse Ltd. 2023-2024 Mo street, 1, 512-33-50
            </div>
        </div>
    )
}