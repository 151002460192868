import React, {useEffect, useState} from 'react'
import Typography from "@mui/material/Typography";
import {PreviewCard} from "../../../components/PreviewCard";
import {Ad} from "../../../types";
import {getAds, getAdsByEmails} from "../../../api/supabase";
import {useAuth0} from "@auth0/auth0-react";
import {AddNew} from "./AddNew";
import {Header} from "../../../components/Header";

export const Listings = () => {
    const {user} = useAuth0()
    const [ads, setAdds] = useState<Ad[]>([])

    useEffect(() => {
        const fetchAds = async () => {
            const ads = await getAdsByEmails(user.email);
            setAdds(ads);
        }
        if (user) {
            fetchAds()
                .catch(console.error);
        }
    }, [user])


    return (<>
        <Header />
        <div
            className='max-w-screen-lg mx-auto mt-10'
        >

            <Typography variant='h3' mb={3}>
                My listings
            </Typography>

            <div className='grid grid-cols-1 md:grid-cols-3 gap-5'>

                {
                    ads.length
                        ? ads.map(({place, name, price, id, images, is_published}) => (
                        <PreviewCard
                            id={id}
                            key={id}
                            image={images[0]}
                            title={name}
                            place={place}
                            price={price}
                            to={`${id}`}
                            is_published={is_published}
                            showStatus
                        />
                    ))
                        : <Typography>No listings yet</Typography>
                }
                <AddNew />
            </div>
        </div>
    </>)
}