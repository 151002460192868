import React from 'react'
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';


const FAQ_CONTENT = [
    {
        question: 'I have only a few pallets. Can I find a place for them only',
        answer: 'Absolutely, our partners have storage solutions for all shapes and sizes. From single items all the way through to whole warehouses.'
    },
    {
        question: 'How I can extend my storage period',
        answer: 'Depending on availability of the partner you may be able to extend your storage. Extra costs might be incurred, alternatively you can chose another partner.'
    },
    {
        question: 'What if I don\'t want to rent the whole warehouse',
        answer: 'That’s fine, just search for the size you want. Venuse was set up with growing businesses in mind so we understand at this time you might only need a small space.'
    },
    {
        question: 'How can I rent my own place (e.g. a garage)',
        answer: 'To rent you own space simply register as a landlord at the top of our page'
    }
]

export const FAQ = () => {
    return (
        <div className='flex justify-center pt-10 pb-20 bg-[#f9f9fe]'>
            <div className='max-w-[560px]'>
                <div className='flex justify-center text-4xl lg:text-3xl text-[#c83232] font-bold mb-8 text-center'>
                    Frequently asked questions
                </div>

                <div>
                    {FAQ_CONTENT.map((content, key) => <Question {...content} key={key}/>)}

                </div>

            </div>

        </div>


    )
}

const Question = ({question, answer}) => {
    return (
        <Accordion elevation={0} sx={{'&:before': {height: '0px'}}}>
            <AccordionSummary
                sx={{backgroundColor: '#f9f9fe', fontWeight: 'bold'}}
                expandIcon={<ExpandMoreIcon sx={{}}/>}>
                {question}
            </AccordionSummary>
            <AccordionDetails sx={{backgroundColor: '#f9f9fe'}}>
                {answer}
            </AccordionDetails>
        </Accordion>
    )
}