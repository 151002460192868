import React, {useState} from 'react'
import Typography from "@mui/material/Typography";
import {FullWidthSearchButton, HeaderSearchButton} from "./HeaderSearchButton";
import {SelectDate} from "./SelectDate";
import {SelectType} from "./SelectType";
import {SelectWhere} from "./SelectWhere";
import {Link} from "react-router-dom";
import dayJs from "dayjs";

export const MobileHeader = (params) => {
    const {
        isOpen,
        setOpen,
        cities,
        onCityChange,
        onTypeChange,
        selectedCity,
        selectedType,
        checkOutDate,
        checkInDate,
        onCheckInDateChange,
        onCheckOutDateChange
    } = params;

    return isOpen
        ? <ExtendedHeader
            selectedCity={selectedCity}
            onCityChange={onCityChange}
            onTypeChange={onTypeChange}
            selectedType={selectedType}
            checkOutDate={checkOutDate}
            checkInDate={checkInDate}
            onCheckInDateChange={onCheckInDateChange}
            onCheckOutDateChange={onCheckOutDateChange}
            cities={cities}/>
        : <CollapsedHeader
            selectedCity={selectedCity}
            selectedType={selectedType}
            checkOutDate={checkOutDate}
            checkInDate={checkInDate}
            onClick={() => setOpen(true)}/>
}

const ExtendedHeader = ({
                            cities,
                            onCityChange,
                            onTypeChange,
                            selectedCity,
                            selectedType,
                            checkOutDate,
                            checkInDate,
                            onCheckInDateChange,
                            onCheckOutDateChange
                        }) => (
    <>
        <div className='border border-gray-400  rounded-3xl w-full mb-4'>
            <SelectWhere cities={cities} onCityChange={onCityChange} selectedCity={selectedCity}/>
        </div>
        <div className='border border-gray-400  rounded-3xl w-full mb-4'>
            <SelectDate title='Check in' selectedDate={checkInDate} onChange={onCheckInDateChange}/>
        </div>
        <div className='border border-gray-400  rounded-3xl w-full mb-4'>
            <SelectDate title='Check out' selectedDate={checkOutDate} onChange={onCheckOutDateChange}/>
        </div>
        <div className='border border-gray-400  rounded-3xl w-full mb-4'>
            <SelectType onTypeChange={onTypeChange} selectedType={selectedType}/>
        </div>
        <Link to='/search' state={{selectedCity, selectedType, checkInDate, checkOutDate}}>
            <FullWidthSearchButton/>
        </Link>
    </>
)


const CollapsedHeader = ({onClick, selectedCity, selectedType, checkInDate, checkOutDate}) => {
    const title = `${selectedType ? capitalizeFirstLetter(selectedType) : 'All types'} ${selectedCity ? `in ${selectedCity}` : 'everywhere'}`;
    const titleWithDefault = selectedType || selectedCity ? title : 'Let\'s find you a storage';
    const checkInFormatted = dayJs(checkInDate)
    const checkInReady = checkInFormatted.isValid() ? checkInFormatted.format('DD MMM') : ''
    const checkOutFormatted = dayJs(checkOutDate)
    const checkOutReady = checkOutFormatted.isValid() ? checkOutFormatted.format('DD MMM') : ''
    const isDate = checkInDate && checkOutDate && checkInFormatted.isValid() && checkOutFormatted.isValid();
    const dateSubtitle = `${checkInReady} - ${checkOutReady}`;

    return (
        <div
            className='shadow shadow-gray-300 rounded-3xl w-full py-4 px-6 flex items-center justify-between'>
            <div onClick={onClick}>
                <Typography>{titleWithDefault}</Typography>
                <Typography color='text.secondary'>
                    {isDate ? dateSubtitle : <>
                        <span className='mr-3'>Select:</span>
                        <span className='mr-3'>City </span>
                        <span className='mr-3'>Date</span>
                        <span className='mr-3'>Type</span>
                    </>}
                </Typography>
            </div>
            <Link to='/search' state={{selectedCity, selectedType, checkInDate, checkOutDate}}>
                <HeaderSearchButton/>
            </Link>
        </div>
    )
}

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}