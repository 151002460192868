import React, {useState} from 'react'

import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {TimePicker} from '@mui/x-date-pickers/TimePicker';
import {useNavigate} from "react-router-dom";
import {PlacePageModal} from "./PlacePageModal";
import {styled} from "@mui/material/styles";


export const MessageButton = styled(Button)(
    () => `
    background: rgb(200, 50, 50);
    height: 66px;
    width: 100%;
    border-radius: 2.5rem;
    &:hover {
        background: #D72542;
}`)

export const Aside = (props) => {
    const {price, id, extraClass = '', isFlatBottom} = props;
    const navigate = useNavigate();
    const [isOpenModel, setModelOpen] = useState(false);


    const [isError, setError] = useState(false)
    const [dateValue, setDate] = useState(null);
    const [startTimeValue, setStartTime] = useState(null);
    const [endTimeValue, setEndTime] = useState(null);

    const onReserveClick = () => {
        const isFilledIn = dateValue && startTimeValue && endTimeValue;
        if (!isFilledIn) {
            return setError(true)
        }

        navigate(`/prebook/${id}`, {
            state: {
                date: dateValue?.format('DD MMMM YYYY'),
                ad: props,
                start: startTimeValue?.format('HH:mm'),
                end: endTimeValue?.format('HH:mm'),
                total: endTimeValue.diff(startTimeValue, 'hours')
            }
        })
    }

    return (
        <div className={`lg:w-auto w-full z-10 ${extraClass}`}>
            <Card
                className='sticky top-0 '
                variant="outlined"
                sx={{
                    borderRadius:  '1.5rem',
                    borderBottomLeftRadius: isFlatBottom ? 0 : '',
                    borderBottomRightRadius: isFlatBottom ? 0 : '',
            }}
            >
                <CardContent sx={{px: 3}}>
                    <Typography
                        mb={3}
                        component="div" variant="h5" className='mx-4 mb-5 '>
                        £ {price ? Math.floor(price / 30) : 0}
                        <Typography
                            color="text.secondary"
                            component="span">
                            /day
                        </Typography>
                    </Typography>
                    <MessageButton
                        sx={{minWidth: '100%', height: '45px', textTransform: 'none'}}
                        variant="contained"
                        color="primary"
                        onClick={() => setModelOpen(true)}
                    >
                        <Typography >
                            Message venue
                        </Typography>
                    </MessageButton>

                </CardContent>
            </Card>

            <PlacePageModal isOpen={isOpenModel} setOpen={setModelOpen} ad={props}/>

        </div>
    )
}
