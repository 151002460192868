import React from 'react';
import Carousel from 'react-material-ui-carousel'

export const PlacePageCarousel = ({images}) => {
    return images && (
        <>
            <DesktopCarousel images={images}/>
            <MobileCarousel images={images}/>
        </>
    )
}


const DesktopCarousel = ({images}) => (
    <div className='lg:flex h-[600px] hidden'>
        <div
            style={{
                backgroundImage: `url(${images[0]})`
            }}
            className='bg-cover bg-center h-full flex-1 border-white border-y-4 border-r-2'>
        </div>
        <div
            style={{
                backgroundImage: `url(${images[1]})`
            }}
            className='bg-cover bg-center h-full flex-1  border-4 border-white'>
        </div>
    </div>
)

export const MobileCarousel = ({images = []}) => {
    return images && (
        <Carousel
            animation='slide'
            autoPlay={false}
            swipe
            navButtonsAlwaysVisible
            className='lg:hidden'
        >
            {
                images.map((image, i) => (<div
                    key={i}
                    style={{
                        backgroundImage: `url(${image})`,
                        height: '500px'
                    }}
                    className='flex items-end justify-center pb-10 bg-cover bg-center h-full w-full border-white border-y-4 border-r-2'>
                </div>))
            }
        </Carousel>
    )
}