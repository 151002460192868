import React from 'react'
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import Repartition from "@mui/icons-material/Repartition";
import Camera from "@mui/icons-material/Camera";
import Fence from "@mui/icons-material/Fence";

export const Facilities = () => {
    return (
        <ListItem alignItems="flex-start" sx={{pl: 0, mb: 5}}>
            <ListItemText
                primary={<Typography variant='h6'>Facilities</Typography>}
                secondary={
                    <>
                        <ListItem
                            alignItems="center"
                            sx={{pl: 0}}
                        >
                            <Icon
                                Icon={Repartition}
                                title='Partial load'
                            />
                            <Icon
                                Icon={Camera}
                                title='CCTV'
                            />
                            <Icon
                                Icon={Fence}
                                title='Guarded'
                            />


                        </ListItem>

                    </>
                }
            />
        </ListItem>
    )
}


const Icon = ({Icon, title}) => {
    return (
        <div className='flex items-center'>
            <Icon
                fontSize='medium'
                htmlColor='#56596C'
            />
            <div className='flex flex-col ml-1 mr-6'>
                <Typography
                    component="span"
                    variant="body2"
                    color="text.secondary"
                >
                    {title}
                </Typography>
            </div>
        </div>
    )
}