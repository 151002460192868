import {styled} from "@mui/material/styles";
import Button from "@mui/material/Button";
import SearchIcon from "@mui/icons-material/Search";
import React from "react";

export const SearchButton = styled(Button)(
    () => `
    background: rgb(200, 50, 50);
    border-radius: 100%;
    height: 66px;
    transform: scale(0.8);
    margin-right: 16px;
    &:hover {
        background: #D72542;
}`)

export const FullSearchButton = styled(Button)(
    () => `
    background: rgb(200, 50, 50);
    height: 66px;
    width: 100%;
    border-radius: 2.5rem;
    &:hover {
        background: #D72542;
}`)

export const HeaderSearchButton = () => {
    return (
        <SearchButton
            variant="contained">
            <SearchIcon fontSize='large'/>
        </SearchButton>
    )
}

export const FullWidthSearchButton = () => (
    <FullSearchButton
        style={{textTransform: 'none', fontSize: 18}}
        variant="contained">
        Search
    </FullSearchButton>
)