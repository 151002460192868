import React, {useRef} from 'react';
import Map, {MapRef, Marker} from 'react-map-gl';
import mapboxgl from "mapbox-gl";
import {Ad} from "../../types";

export const AdsMap = ({ads}: {ads: Ad[]}) => {
    const mapRef = useRef<MapRef>();
    const bounds = new mapboxgl.LngLatBounds()
    const popup = (text) => {
        return new mapboxgl.Popup().setText(text);
    };

    ads.forEach(ad => {
        bounds.extend([ad.longitude, ad.latitude])
    })

    mapRef.current?.fitBounds(
        bounds,
        {padding: 100, duration: 1000}
    );


    return (<Map
        ref={mapRef}
        cooperativeGestures
        mapboxAccessToken={process.env.MAP_TOKEN}
        initialViewState={{
            longitude: -2.5,
            latitude: 54,
            zoom: 5.8
        }}
        style={{height: '100%'}}
        mapStyle="mapbox://styles/mapbox/streets-v9"
    >
        {
            ads.map(({name, longitude, latitude}, index) => (
                <Marker
                    key={index}
                    longitude={longitude}
                    latitude={latitude}
                    popup={popup(name)}
                    color='red'
                    onClick={(args) => console.log(args)}
                />
            ))
        }

    </Map>)
}
