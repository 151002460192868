import React from 'react'
import {PreviewCard} from "./PreviewCard";
import Typography from "@mui/material/Typography";
import Switch from '@mui/material/Switch';
import {FormControlLabel} from "@mui/material";
import {Props} from "../../pages/Landing/types";


export const Ads = ({ads, totalAdNumber, setShowMap, showMap, showTitle}: Props) => {
    const areNoResults = ads.length === 0;
    return (
        <div className='px-4 max-w-[1120px] w-full pb-20 hide md:block'>
            {showTitle && !areNoResults  && <div className='text-[40px] flex justify-center pt-10 bold text-center'>
                Storage nearby
            </div>}
            {!areNoResults && <div className='py-5 justify-between flex text-gray-500'>
                <Typography>
                    Showing {ads.length} out of {totalAdNumber} locations
                </Typography>
                <div className='hidden lg:block'>
                    <Typography>
                        <FormControlLabel
                            control={<Switch value={showMap} onChange={(e) => setShowMap(e.target.checked)}/>}
                            label="Show map" labelPlacement="start"
                        />
                    </Typography>
                </div>
            </div>}
            <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-y-10  gap-x-8'>
                {
                    ads.map(({place, name, price, id, images, city}) => (
                        <PreviewCard
                            onClick={() => console.log(id)}
                            id={id}
                            key={id}
                            image={images[0]}
                            title={name}
                            place={place}
                            price={price}
                            city={city}
                        />
                    ))
                }
            </div>
            {
                areNoResults && <div>
                <div className='font-bold text-3xl text-center mb-8'>
                    O results
                </div>
                <div className='text-secondary text-center'>
                    Try to change your request
                </div>
                </div>
            }
        </div>
    )
}
