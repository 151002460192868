import React from "react";
import {styled} from "@mui/material/styles";
import MuiButton from "@mui/material/Button";


export const FullSearchButton = styled(MuiButton)(
    () => `
    background: #56596C;
    text-transform: capitalize;
    height: 40px;
    width: 142px;
    border-radius: 2.5rem;
    font-size: 16px;
    line-height: 24px;
    &:hover {
        background: #56596D;
}`)

export const Button = ({children, ...rest}) => {
    return (
        <FullSearchButton
            {...rest}
            variant="contained">
            {children}
        </FullSearchButton>
    )
}